// Packages
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { IonApp, IonButton, setupConfig, IonModal } from "@ionic/react";
import { inject, observer } from "mobx-react";
import { action, computed, observable, when } from "mobx";
import { notification } from "antd";

// Components
import EmptyLayout from "./shared/EmptyLayout/EmptyLayout";
import SplitLayout from "./shared/SplitLayout/SplitLayout";
import Home from "./pages/Home/Home";
import Terms from "./pages/Terms/Terms";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import Privacy from "./pages/Privacy/Privacy";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Dashboard from "./pages/Actions/Dashboard";
import CheckIns from "./pages/CheckIns/CheckIns";
import CheckIn from "./pages/CheckIn/CheckIn";
import Goals from "./pages/Goals/Goals";
import Goal from "./pages/Goal/Goal";
import Inspiration from "./pages/Inspiration/Inspiration";
import UserProfile from "./pages/UserProfile/UserProfile";
import Support from "./pages/Support/Support";
import RoutineProgress from "./shared/RoutineProgress/RoutineProgress";
import { load, page, identify, track } from "./plugins/analytics";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Ionic Theme */
import "./theme/theme.scss";
import { postData } from "./plugins/auth/auth";
import CallBack from "./pages/Callback/CallBack";
import AccessCode from "./pages/AccessCode/AccessCode";
import { cookieUtil } from "./utils";

interface IProps {
  userStore: any;
  activityStore: any;
  appStore: any;
}

// declare let nativeFunctions:any;

@inject("userStore", "activityStore", "appStore")
@observer
class App extends React.Component<any> {
  constructor(props: any) {
    super(props);
    load();
    identify();
    when(
      () => this.serviceWorkerUpdated,
      () => this.showServiceWorkerNotification()
    );
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    page();
  }

  @observable public baseInit: any = {
    headers: {},
    response: true,
  };
  @computed get serviceWorkerUpdated() {
    const { serviceWorkerRegistration } = this.props.appStore;
    return serviceWorkerRegistration != null;
  }

  public showServiceWorkerNotification = () => {
    const { serviceWorkerRegistration } = this.props.appStore;

    if (!serviceWorkerRegistration) {
      return;
    }

    // based on default create-react-app's service worker implementation
    // https://medium.com/better-programming/let-users-know-when-you-have-updated-your-service-worker-in-create-react-app-b0c2701995b3
    const skipWaitingForServiceWorker = (
      swRegistration: ServiceWorkerRegistration
    ) => {
      const registrationWaiting = swRegistration.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });

        registrationWaiting.addEventListener("statechange", (e: any) => {
          if (e.target && e.target.state === "activated") {
            track("Updated App");
            window.location.reload();
          }
        });
      }
    };

    const button = (
      <IonButton
        color="primary"
        onClick={() => skipWaitingForServiceWorker(serviceWorkerRegistration)}
      >
        Update
      </IonButton>
    );

    notification.open({
      message: "New version available",
      description:
        "A new version of Goalden Hour is available! Would you like to update?",
      duration: null,
      btn: button,
      onClick: () => skipWaitingForServiceWorker(serviceWorkerRegistration),
    });
  };



  public componentDidMount() {
    this.props.userStore.loadAccessCodeStatus();

    this.props.userStore.getUserInfo();
    const cookies = document.cookie.split("; ");
    const invitationIdCookie = cookies.find((row) =>
      row.startsWith("invitationId=")
    );

    console.log("inv id",invitationIdCookie);
    
    if (invitationIdCookie) {
      const invitationIdEncrypted =
        invitationIdCookie && invitationIdCookie.split("=")[1]; // Get the invitationId cookie
      const invitationId =
        invitationIdEncrypted && JSON.parse(atob(invitationIdEncrypted)); // Decrypt the invitationId cookie

        
      if (!invitationId) {
        return;
      }
      postData(
        "invitation",
        `/invitation/${invitationId.invitationId}`,
        this.baseInit
      ).then((res) => {
        document.cookie =
          "invitationId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        notification.success({
          message: "Invitation accepted!",
          description: "You have successfully accepted the invitation.",
        });
        this.props.userStore.fetchAssociation();
      });
    }
  }

  componentWillMount() {
    setupConfig({
      mode: "md",
    });
  }

  @action.bound
  public closeRoutineProgressModal() {
    this.props.activityStore.createRoutineProgressModal = false;
  }

  render() {
  
    const urlParams = new URLSearchParams(window.location.search)
    if (window.location.href.includes("signup") && urlParams.get("invite")) {
     
      const inviteParam = urlParams.get("invite");
  console.log("inv params",inviteParam);
  
      document.cookie = `invitationId= ${inviteParam}`;
  }
  
    return (
      <IonApp>
        <Route path="/" component={Home} exact />
        <EmptyLayout path="/terms-of-use" component={Terms} exact />
        <Route path="/googlecallback" component={CallBack} exact />
        <EmptyLayout path="/privacy-policy" component={Privacy} exact />
        <EmptyLayout path="/access-code" component={AccessCode} exact />
      
        
        <Route
          path="/signup"
          render={(props) => {
            const { isAccessCodeVerified } = this.props.userStore;
            return isAccessCodeVerified ? (
              <SignUp {...props} userStore={this.props.userStore} />
            ) : (
              <Redirect to="/access-code" />
            );
          }}
          exact
        />
   
        <EmptyLayout path="/signin" component={SignIn} exact />
        <EmptyLayout path="/confirm-email" component={ConfirmEmail} exact />
        <EmptyLayout path="/forgot-password" component={ForgotPassword} exact />
        <EmptyLayout path="/reset-password" component={ResetPassword} exact />
        <SplitLayout path="/actions" component={Dashboard} exact />
        <SplitLayout path="/reviews-reflect" component={CheckIns} exact />
        <SplitLayout path="/reviews-reflect/:id" component={CheckIn} exact />
        <SplitLayout path="/goals" component={Goals} exact />
        <SplitLayout path="/goals/:id" component={Goal} exact />
        <SplitLayout path="/inspiration" component={Inspiration} exact />
        <SplitLayout path="/profile" component={UserProfile} exact />
        <SplitLayout path="/support" component={Support} exact />

        {this.props.userStore.showCelebration ? (
          <img
            src={`${process.env.PUBLIC_URL}/assets/confetti.gif`}
            alt="fireworks"
            className="celebration-gif"
          />
        ) : null}
        <IonModal
          isOpen={this.props.activityStore.createRoutineProgressModal}
          border-radius="7"
          backdropDismiss={false}
          cssClass={"routine-progress-modal"}
        >
          <div className="ion-padding modal-content">
            <button
              className="x-button"
              onClick={() => this.closeRoutineProgressModal()}
            >
              x
            </button>
            <RoutineProgress />
          </div>
        </IonModal>
      </IonApp>
    );
  }
}

export default withRouter(App);
